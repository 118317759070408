@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
background-color: #232020;;/*#f3f4f6#303030*/;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root.hide-overlay{
  overflow: hidden;
}

#root{
  width: 100vw;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

ul,li{
  list-style-type: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
}

