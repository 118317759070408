.swiper-button-next{
    height: 100% !important;
    width: 50px !important;
    background-color: #0000006b;
    top:5% !important;
    right:0% !important;
}
.swiper-button-prev{
    height: 100% !important;
    width: 50px !important;
    background-color: #0000006b;
    top:5% !important;
    left:0% !important;
    /* color:#383838'; */
}
.swiper-button-next:after {
    color:#fff;
    background-color: #A62727;
    font-size: 20px !important;
    padding:0.5rem;
    border-radius: 50px;
}
.swiper-button-prev:after{
    background-color: #A62727;
    color:#fff;
    font-size: 20px !important;
    padding: 0.5rem;
    border-radius: 50px;
}